import React, { Fragment, useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import PromoComponent from "./PromoComponent";
import AnnouncementPanel from "./AnnouncementPanel";
import Give20Modal from "../Give20Modal";
import axios from "axios";
import CartDialogModal from "../CartDialogModal";
import LeftNavModal from "../LeftNavModal";
import SizingModal from "../SizingModal";
import ReviewsModal from "../ReviewsModal";

export default (Child, shouldHideCart) => {
  return (props) => {
    const [cart, setCart] = useState([]);
    const [is20ModalVisible, setIs20Modalvisible] = useState(false);
    const [shouldDisplayPromo, setShouldDisplayPromo] = useState(false);
    const [isLeftNavModalVisible, setIsLeftNavModalVisible] = useState(false);
    const [isCartDialogModalVisible, setIsCartDialogModalVisible] =
      useState(false);
    const [isSizingModalVisible, setIsSizingModalVisible] = useState(false);
    const [categories, setCategories] = useState([]);
    const [reviews, setReviews] = useState([]);
    const [isReviewsModalVisible, setIsReviewsModalVisible] = useState(false);

    useEffect(() => {
      axios.get("/getCategories").then((res) => setCategories(res.data));
    }, []);

    const updateCart = async () => {
      let updatedCart = await axios.get("/getCart").then((res) => res.data);
      setCart(updatedCart);
      if (
        window.location.pathname.includes("/cart") &&
        !window.location.pathname.includes("success") &&
        !updatedCart.length
      ) {
        window.location = "/";
      }
    };

    useEffect(() => {
      const anyModalOpen =
        is20ModalVisible ||
        isLeftNavModalVisible ||
        isCartDialogModalVisible ||
        shouldDisplayPromo ||
        isSizingModalVisible ||
        isReviewsModalVisible;
      if (anyModalOpen) {
        disableBodyScroll();
      } else {
        resetBodyScroll();
      }
    }, [
      is20ModalVisible,
      isLeftNavModalVisible,
      isCartDialogModalVisible,
      shouldDisplayPromo,
      isSizingModalVisible,
      isReviewsModalVisible,
    ]);

    const disableBodyScroll = () => {
      const root = document.getElementById("root");
      const scrollY = window.scrollY;
      root.style.position = "fixed";
      root.style.width = "100%";
      root.style.top = `-${scrollY}px`;
    };

    const resetBodyScroll = () => {
      const root = document.getElementById("root");
      const scrollY = root.style.top;
      root.style.position = "";
      root.style.top = "";
      window.scrollTo(0, parseInt(scrollY || "0") * -1);
    };

    const addToCart = async (data) => {
      await axios(`/addToCart`, {
        method: "POST",
        data: [data],
      });
      updateCart();
      setIsCartDialogModalVisible(true);
    };

    const declinePromo = () => {
      localStorage.setItem("promoDeclined", true);
      setShouldDisplayPromo(false);
    };

    useEffect(() => {
      updateCart();

      const intervalId = setInterval(() => {
        checkForPromo();
      }, 1000);

      return () => clearInterval(intervalId);
    }, []);

    const checkForPromo = () => {
      const declined = localStorage.getItem("promoDeclined");
      if (declined) return;

      const timeout = localStorage.getItem("promoTimeout");

      if (timeout && timeout < Date.now() && window.innerWidth > 500) {
        setShouldDisplayPromo(true);
      }
    };

    const showReviewsForProduct = async (productId) => {
      const existingReviews = await axios
        .post("/listReviewsByProductId", { productId })
        .then((res) => res.data.reviews);
      setReviews(existingReviews);
      setIsReviewsModalVisible(true);
    };

    return (
      <>
        <Give20Modal
          isActive={is20ModalVisible}
          setModalVisible={setIs20Modalvisible}
        />
        <CartDialogModal
          cart={cart}
          updateCart={updateCart}
          modalVisible={isCartDialogModalVisible}
          setModalVisible={setIsCartDialogModalVisible}
          addToCart={addToCart}
        />
        <ReviewsModal
          reviews={reviews}
          modalVisible={isReviewsModalVisible}
          setModalVisible={setIsReviewsModalVisible}
        />
        <SizingModal
          isActive={isSizingModalVisible}
          setModalVisible={setIsSizingModalVisible}
        />
        <LeftNavModal
          categories={categories}
          modalVisible={isLeftNavModalVisible}
          setModalVisible={setIsLeftNavModalVisible}
        />
        <AnnouncementPanel
          displayPromo={() => setShouldDisplayPromo(true)}
          displayGive20={() => setIs20Modalvisible(true)}
        />
        <Header
          shouldHideCart={shouldHideCart}
          setIsCartDialogModalVisible={setIsCartDialogModalVisible}
          updateCart={updateCart}
          cart={cart}
          setIsLeftNavModalVisible={setIsLeftNavModalVisible}
        />
        {shouldDisplayPromo && <PromoComponent declinePromo={declinePromo} />}

        <Fragment>
          <Child
            {...props}
            cart={cart}
            categories={categories}
            showReviewsForProduct={showReviewsForProduct}
            updateCart={(options) => {
              updateCart();
              if (!options?.shouldHideCartModal) {
                setIsCartDialogModalVisible(true);
              }
            }}
            addToCart={addToCart}
            setIsSizingModalVisible={setIsSizingModalVisible}
          />
        </Fragment>
        <Footer setIs20Modalvisible={setIs20Modalvisible} />
      </>
    );
  };
};
