import React, { useEffect } from "react";
import { Player, ControlBar } from "video-react";

export default (props) => {
  const { src, height, width, style, fluid = true, onClick } = props;
  let playerRef = null;

  useEffect(() => {
    setTimeout(() => {
      if (playerRef !== null) playerRef.play();
    }, 500);
  }, []);

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        cursor: "pointer",
        pointerEvents: onClick ? "all" : "none",
      }}
    >
      <div
        onClick={onClick}
        style={{
          paddingTop: "150%",
          position: "absolute",
          height: "100%",
          width: "100%",
          zIndex: 3,
        }}
        {...props}
      ></div>
      <Player
        width={width}
        height={height}
        fluid={fluid}
        muted
        ref={(player) => {
          playerRef = player;
        }}
        playsInline
        loop
        preload="auto"
        src={src}
        {...props}
      >
        <ControlBar disableCompletely />
      </Player>
    </div>
  );
};
